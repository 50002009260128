<template>
  <div class="el-input-range-slider">
    <client-only>
      <label v-if="label">{{ label }}</label>
      <Slider v-model="value" class="__range-sliider" :min="min" :max="max" :step="step" :show-tooltip="showTooltip" :format="{prefix, suffix, decimals}" />
      <span class="__selected-range-display">{{ displayValue }}</span>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';
import {ComputedRef, PropType, Ref} from 'vue';
import {DropdownItem} from '~/@types/common';

// DATA
const value: Ref<number[]> = ref([0, 2]);

// PROPS
const props = defineProps({
  modelValue: {
    type: Array as PropType<number[]>,
    default: null,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  showTooltip: {
    type: String as PropType<'always' | 'focus' | 'drag'>,
    default: 'always',
  },
  prefix: {
    type: String,
    default: '',
  },
  suffix: {
    type: String,
    default: '',
  },
  decimals: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: '',
  },
});

/**
 * Display value
 */
const displayValue: ComputedRef<string> = computed(() => {
  const [start, end] = value.value; // Assuming value.value is an array with two elements

  // Function to determine the appropriate format for a number
  const formatNumber = (num: number) => {
    if (num >= 1) {
      return `${num} M`;
    } else {
      return `${num * 1000} k`;
    }
  };

  return `$ ${formatNumber(start)} - ${formatNumber(end)}`;
});

/**
 * Component MOUNTED!
 */
onMounted(() => {
  value.value = props.modelValue;
});

watch(() => value.value, () => {
  updateModelValue();
});


// EMIT Definitions
const emit = defineEmits<{
  (event: 'update:modelValue', payload: DropdownItem | string | number | object | null): void;
}>();

/**
 * Update model value
 */
function updateModelValue () {
  emit('update:modelValue', value.value);
}
</script>

<style lang="scss">
.el-input-range-slider {
  margin-top: 30px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;

  .__range-sliider {
    width: 100%;

    .slider-tooltip, .slider-connect {
      background: rgb(var(--background-dark));
      border-color: rgb(var(--background-dark));
    }
  }

  label, .__selected-range-display {
    width: fit-content;
    padding: 0 12px;
    white-space: nowrap;
    color: rgb(var(--text-lighter-1));
  }


  // RESPONSIVENESS
  @media screen and (max-width: $grid-md) {
    justify-content: space-between;

    .__range-sliider {
      position: absolute;
      width: 100%;
      top: -16px;
      padding: 0 18px;
    }
  }
}
</style>
