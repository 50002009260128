<template>
  <div class="blocks-other-search-popup" :class="{'--open': isOpen}">
    <div class="row __other-search-popup-inner">
      <div v-if="!isHidden('property-type')" class="col-md-4">
        <ElementsInputsDropdown v-model="selectedPropertyType" label="Property Type" :items="propertyTypeItems" outlined @update:model-value="updateModelValue" />
      </div>
      <div v-if="!isHidden('bedrooms')" class="col-md-4">
        <ElementsInputsDropdown v-model="bedrooms" label="Bedrooms" :items="items" outlined @update:model-value="updateModelValue" />
      </div>
      <div v-if="!isHidden('bathrooms')" class="col-md-4">
        <ElementsInputsDropdown v-model="bathrooms" label="Bathrooms" :items="items" outlined @update:model-value="updateModelValue" />
      </div>
      <div v-if="!isHidden('livingAreas')" class="col-md-4">
        <ElementsInputsDropdown v-model="livingAreas" label="Living Areas" :items="items" outlined @update:model-value="updateModelValue" />
      </div>
      <div v-if="!isHidden('parking')" class="col-md-4">
        <ElementsInputsDropdown v-model="garageSpaces" label="Parking spaces" :items="items" outlined @update:model-value="updateModelValue" />
      </div>
      <div v-if="!isHidden('budget')" class="col-md-12">
        <ElementsInputsRangeSlider v-model="budget" class="__budget-range" :min="0" :max="10" :step="0.2" suffix=" M" label="Budget:" @update:model-value="updateModelValue" />
      </div>
      <!--      <div class="col-md-12" style="padding-top: 20px">-->
      <!--        <ElementsInputsCheckboxGroup />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import {PropType, Ref} from 'vue';
import {DropdownItem} from '~/@types/common';
import {propertyTypes} from '~/composables/propertyTypes';
import {ListingType} from '~/@types/listing';

export interface FilterParams {
  listingTypes?: ListingType[];
  bedrooms?: string;
  bathrooms?: string;
  garageSpaces?: string;
  livingAreas?: string;
  budget?: [number, number];
}

// EMIT Definitions
//----------------------------------------------------------------------------------------------------------------------
const emit = defineEmits<{
  (event: 'update:modelValue', payload: FilterParams): any;
}>();

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  modelValue: {
    type: Object as PropType<FilterParams>,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  hiddenFilters: {
    type: Array as PropType<string[]>,
    default: () => ['property-type'],
  },
});

// DATA Definitions
//----------------------------------------------------------------------------------------------------------------------
const selectedPropertyType: Ref<string> = ref('');
const bedrooms: Ref<string> = ref('');
const bathrooms: Ref<string> = ref('');
const garageSpaces: Ref<string> = ref('');
const livingAreas: Ref<string> = ref('');
const budget: Ref<[number, number]> = ref([0, 2] as [number, number]);

const items: Ref<DropdownItem[]> = ref([
  {label: 'any', value: null},
  {label: '1', value: '1'},
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '4+', value: '>4'},
]);

const propertyTypeItems: Ref<DropdownItem[]> = ref(propertyTypes);

/**
 * Check for hidden filters
 *
 * @param itemName
 */
function isHidden (itemName: string) {
  return props.hiddenFilters && props.hiddenFilters.includes(itemName);
}

function updateModelValue () {
  const modelValue: FilterParams = {
    ...(!isHidden('property-type') && {listingTypes: selectedPropertyType.value ? [selectedPropertyType.value] as ListingType[] : []}),
    ...(!isHidden('bedrooms') && {bedrooms: bedrooms.value}),
    ...(!isHidden('bathrooms') && {bathrooms: bathrooms.value}),
    ...(!isHidden('livingAreas') && {livingAreas: livingAreas.value}),
    ...(!isHidden('parking') && {garageSpaces: garageSpaces.value}),
    ...(!isHidden('budget') && {budget: budget.value}),
  };

  emit('update:modelValue', modelValue);
}
</script>

<style lang="scss">
.blocks-other-search-popup {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  width: 98%;
  margin: 0 auto;
  max-height: 0;
  overflow: hidden;
  height: fit-content;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: rgba(var(--background-light), 1);
  box-shadow: 0 6px 8px -4px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 0 solid rgba(var(--text-lighter-1), 0.3);
  transform: translate(0, -50px);
  scale: 0.5;
  opacity: 0;
  transition: all 0.2s ease-in;

  .__other-search-popup-inner {
    padding-bottom: 85px;
  }

  .__budget-range {
    margin-top: 60px;
  }

  &.--open {
    border-top: 1px solid rgba(var(--text-lighter-1), 0.3);
    padding: 20px 20px 160px;
    overflow: auto;
    max-height: 100vh;
    scale: 1;
    transform: translate(0, 0);
    transition: all 0.2s ease-in;
    opacity: 1;
  }


  // RESPONSIVENESS
  // -------------------------------------------------------------------------------------------------------------------
  @media screen and (max-width: $grid-sm) {
    &.--open {
      padding: 30px 12px
    }

    .__budget-range {
      margin-top: 80px;
    }
  }
}
</style>
